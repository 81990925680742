import React from "react";
import {connect} from "react-redux";
import imgSrc1 from '../../../../assets/film-images/Feature Films Images/1 Nirakar  Chhaya/6 Nirakar Chhayya.jpg';
import imgSrc2 from '../../../../assets/film-images/Feature Films Images/2 Katho Upanishd/Upanishad Publicity Still 3.jpg'; //final
import imgSrc3 from '../../../../assets/film-images/Feature Films Images/3 Rati Chakravyuh/2 Rati Chakravyuh.jpg'; //final
import imgSrc4 from '../../../../assets/film-images/Feature Films Images/4 Kalkimanthankatha/6 Kalkimanthankatha Publicity Photo.jpg'; //final
import imgSrc5 from '../../../../assets/film-images/Feature Films Images/5 Aapothkalin Trikalika/3 The Emergency of Kali copy.jpg'; //final
import imgSrc6 from '../../../../assets/film-images/Feature Films Images/6 Vrindavani Vairagya/Vrindavani Publicity Still 7.jpg'; //final
import imgSrc7 from '../../../../assets/film-images/Feature Films Images/7 Namanush Premer Kothamala/Glossary of Non Human Love 5.jpg'; //final
import imgSrc8 from '../../../../assets/film-images/Feature Films Images/8 Vidhvastha/Vidhvastha Publicity Still 17.jpg'; //final
import {sketchExplored, titleLoaded} from "../../../actions/UIActions";
import Sketch from "react-p5";


function SketchElem(props) {

    let width = window.innerWidth;
    let height = window.innerHeight;
    let winAspectRatio = width / height;
    let tileSize = Math.min(width,height) / 5;

    let img1;
    let img2;
    let mask;
    let tempImg;
    let touched = false;

    const imgArrays = [imgSrc1, imgSrc2, imgSrc3, imgSrc4, imgSrc5, imgSrc6, imgSrc7, imgSrc8];
    const randomIndex1 = Math.floor(Math.random() * imgArrays.length);
    let randomIndex2;

    // Ensure randomIndex2 is different from randomIndex1
    do {
        randomIndex2 = Math.floor(Math.random() * imgArrays.length);
    } while (randomIndex1 === randomIndex2);

    const preload = (p5) => {
        img1 = p5.loadImage(imgSrc1)
        img2 = p5.loadImage(imgSrc2)
    }
    const resizeMaintainAspectRatio = (img) => {
        const aspectRatio = img.width / img.height;
        if (winAspectRatio > aspectRatio) {
            img.resize(width, width / aspectRatio)
            img.resize(width, width / aspectRatio)
        } else {
            img.resize(height * aspectRatio, height)
            img.resize(height * aspectRatio, height)
        }
    }

    const setup = (p5, parent) => {
        props.dispatch(titleLoaded());
        p5.createCanvas(width,height).parent(parent)
        p5.background(0)
        resizeMaintainAspectRatio(img1);
        resizeMaintainAspectRatio(img2);
        mask = p5.createGraphics(img2.width, img2.height);
        mask.noStroke()
        p5.translate(width/2, height/2);
        p5.background(0);
        p5.fill(255);
        p5.image(img1, -img1.width/2, -img1.height/2);
        mask.translate(img2.width/2, img2.height/2);
    }

    const windowResized = p5 => {
        console.log("hello");
        width = window.innerWidth;
        height = window.innerHeight;
        winAspectRatio = width / height;
        tileSize = Math.min(width,height) / 5;
        p5.resizeCanvas(width, height);
        p5.background(0)
        resizeMaintainAspectRatio(img1);
        resizeMaintainAspectRatio(img2);
        mask = p5.createGraphics(img2.width, img2.height);
        mask.noStroke()
        p5.translate(width/2, height/2);
        p5.background(0);
        p5.fill(255);
        p5.image(img1, -img1.width/2, -img1.height/2);
        mask.translate(img2.width/2, img2.height/2);//
    }

    const touchMoved = p5 => {
        if(img1) {
            p5.image(img1, -img1.width/2, -img1.height/2);
        }
        if(mask) {
            for (let touch of p5.touches) {
                mask.rect(
                    (touch.x - width / 2 - tileSize / 2),
                    (touch.y - height / 2 - tileSize / 2),
                    tileSize,
                    tileSize
                );
            }
            (tempImg = img2.get()).mask(mask.get());
        }
        if(img2) {
            p5.image(tempImg, -img2.width / 2, -img2.height / 2);
        }
        if(!touched)
        {
            touched = true;
            props.dispatch(sketchExplored());
        }
    }

    return (
        <div style={{
            width: "100%",
            height: "100%",
            position: "relative",
        }}
        >
            <div className={'preloader'} style={{
                position: "absolute",
                zIndex: 1,
                top: 0,
            }}>
                <div className={'preloader-text'}>
                    ASHISH AVIKUNTHAK
                </div>
            </div>
            <Sketch
                style={{
                    position: "absolute",
                    zIndex: 2,
                    top: 0,
                }}
                preload={preload} touchMoved={touchMoved} setup={setup} windowResized={windowResized}/>
        </div>
    );
}

export default connect()(SketchElem);