import React, {useEffect} from "react";
import {connect} from "react-redux";

function HomeTitle(props) {
    const title = 'Ashish Avikunthak';
    const up = true;

    return (
            <div className={'avikunthakHome'}>
                <span className={'avikunthakTitle'}>
                {props.titleLoaded?title.toUpperCase():""}
                </span>
            </div>
    );
}

function mapStateToProps(state) {
    return {
        titleLoaded: state.uiReducer.titleLoaded
    };
}

export default connect(mapStateToProps)(HomeTitle);