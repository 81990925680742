import {PRELOADED, SET_BG, SKETCH_EXPLORED, TITLE_LOADED} from "../constants/UIConstants";

export function setBg(color) {
    return {
        type: SET_BG,
        color: color
    }
}

export function sketchExplored() {
    return {
        type: SKETCH_EXPLORED
    }
}

export function preloaded() {
    return {
        type: PRELOADED
    }
}

export function titleLoaded() {
    return {
        type: TITLE_LOADED
    }
}